const api_domain = "https://api.tapgro.com/";
// const api_domain = "https://dev.tapgro.com/";

const api_host = api_domain + "api/V1/";
const api_endpoints = {
  inventoryProductListGet: "getInventoryProduct",
  inventoryAllProductListGet: "getInventoryProductList",
  inventoryStockAdd: "addBarcode/",
  inventoryEditeProduct: "editInventoryProduct/",
  inventoryProductAdd: "addInventoryProduct",
  authSessionCreate: "sendotp",
  verifyOtp: "verifyotp",
  loginUser: "login",
  resendOtp: "sendotp",
  packagesList: "get_packages",
  packageEdit: "edit_package/",
  packageDelete: "delete_package/",
  packageAdd: "add_package",
  getStaff: "getStaff",
  getClient: "getClientContact",
  membersAdd: "add_membership",
  membersList: "get_membership",
  membersDelete: "delete_membership/",
  membersEdit: "edit_membership/",
  getServices: "get_services",
  bookAppoimentAdd: "add_NewAppointmentDetails",
  getBookAppoiment: "getAppointmentDetails",
  bookAppoimentEdite: "editAppointmentDetails/",
  allAppointmentDetails: "getAppointmentDetailsWebFullCal",
  staffAdd: "add_NewStaff",
  staffList: "getStaff",
  staffEdit: "edit_Staff/",
  clientList: "getClient",
  clientAdd: "addClient",
  staffDelete: "deleteStaff/",
  enquiryAdd: "addEnquiry",
  enquiryList: "getEnquiry",
  enquiryEdite: "editEnquiry/",
  getStaffAvailabilty: "get_staff_availabilty_hours/",
  clientDetailesUpdate: "editClient/",
  getAllAppointments: "getAllAppointments/",
  getAllInvoceHistory: "invoceHistory/",
  staffAvailabiltyCalendar: "getstaff_availabilty_calendar",
  staffAvailabiltyCalendarAdd: "staff_availabilty_calendar",
  recordSaleAdd: "processCheckout",
  recordSaleList: "getCheckout",
  listRecordInventory: "listRecordInventory/",
  recordInventory: "recordInventoryUsage/",
  staffAvailabiltyHours: "staff_availabilty_hours",
  InsightLits: "insights/",
  dashboradInsightsList: "dashboardInsights",
  generateRandomBarcode: "generateRandomBarcode",
  getSubServicesSearch: "getSubServicesSearch",
  miniWebsite: "getSellerWebProfile/",
  miniAppointmentBook: "webContactForm",
  getCoupon: "getCoupon",
  getProfileStatus: "get_profile_status",
  getLocationFromPinCode: "getLocationFromPinCode/",
  allServicesTaken: "getClientServiceTaken/",
  addReminder: "setReminder/",
  getTemplates: "getTemplates",
  getCampaignTemplate: "getCampaignTemplate",
  getTemplateDetails: "getTemplateDetails",
  campaignAutomationReminder: "getCampaignAutomationReminder",
  createCampaignAutomationReminder: "createCampaignAutomationReminder",
  updateAppointmentStatus: "updateAppointmentStatus/",
  cancelAppointment: "cancelAppointment",
  initialCampaignData: "initialCampaignData",
  getTemplateImageList: "getTemplateImageList",
  templateImageList: "templateImageList",
  addCampaignTemplate: "addCampaignTemplate",
  getAllCustomers: "getAllCustomers",
  getAdvanceFilters: "getAdvanceFilters",
  sendCampaign: "saveAdvanceFilters",
  sendCampaignMessage: "sendCampaignMessage",
  reviewCampaign: "reviewCampaign",
  walletList: "getWalletTransactions/",
  contactUsApi: "webContactForm",
  addBalanceApi: "addClientBalance/",
  deleteReminder: "deleteReminder/",
  getAllExpenses: "get_AllExpenses",
  addExpense: "add_Expenses",
  editExpense: "edit_Expenses/",
  deleteExpense: "deleteExpenses/",
  getExpense: "get_Expenses/",
  getAllExpenseCategories: "get_AllExpenseCategories",

  clientSendOtp: "clientSendOtp",
  verifyOtpClient: "verifyOtpClient",
  updateClientDetails: "updateClientDetails",
  getClientDetails: "getClientDetails",
  getClientInvoices: "getClientInvoices",
  getClientAppointments: "getClientAppointments",
  getClientMembership: "getClientMembership",
  assignClientLoyalty: "assignClientLoyalty",
};

export { api_host, api_endpoints, api_domain };