import { createSlice } from "@reduxjs/toolkit";
import { miniWebsiteGet, miniAppointmentBook } from "./index";

const miniWebsiteSlice = createSlice({
  name: "miniWebsiteSlice",
  initialState: {
    listMiniWebsite: {},
    appointmentBook: {},
    branches: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(miniWebsiteGet.pending, (state) => {})
      .addCase(miniWebsiteGet.fulfilled, (state, action) => {
        state.listMiniWebsite = action.payload;
        state.branches = action?.payload?.data?.profileHeader?.branch || [];
      })
      .addCase(miniWebsiteGet.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
      })
      .addCase(miniAppointmentBook.pending, (state) => {})
      .addCase(miniAppointmentBook.fulfilled, (state, action) => {
        state.appointmentBook = action.payload;
      })
      .addCase(miniAppointmentBook.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
      });
  },
});

export default miniWebsiteSlice.reducer;
