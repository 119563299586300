import { createSlice } from "@reduxjs/toolkit";
import {
  addRecordSale,
  listRecordSales,
  editRecordSale,
  fetchRecordData,
} from "./recordSaleActions";

const recordSaleSlice = createSlice({
  name: "recordSaleSlice",
  initialState: {
    addedRecordSale: {},
    listedRecordSales: {},
    listedRecordSalesIsLoading: false,
    editedRecordSale: {},
    recordData: null,
    error: null,
  },
  reducers: {
    clearRecordSalesData: (state) => {
      state.addedRecordSale = {};
    },
    clearEditedRecordSale: (state) => {
      state.editedRecordSale = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addRecordSale.pending, (state) => {})
      .addCase(addRecordSale.fulfilled, (state, action) => {
        state.addedRecordSale = action.payload;
      })
      .addCase(addRecordSale.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(listRecordSales.pending, (state) => {
        state.listedRecordSalesIsLoading = true;
      })
      .addCase(listRecordSales.fulfilled, (state, action) => {
        state.listedRecordSales = action.payload;
        state.listedRecordSalesIsLoading = false;
      })
      .addCase(listRecordSales.rejected, (state, action) => {
        state.error = action.payload;
        state.listedRecordSalesIsLoading = false;
      })
      .addCase(editRecordSale.pending, (state) => {})
      .addCase(editRecordSale.fulfilled, (state, action) => {
        state.editedRecordSale = action.payload;
      })
      .addCase(editRecordSale.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(fetchRecordData.fulfilled, (state, action) => {
        state.recordData = action.payload;
      })
      .addCase(fetchRecordData.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});
export const { clearRecordSalesData, clearEditedRecordSale } =
  recordSaleSlice.actions;

export default recordSaleSlice.reducer;
