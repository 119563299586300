import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../apis/api";
import ToastMessage from "../../component/toast/ToastMessage";
import { loadingEnd, loadingStart } from "../setLoading/setLoading";

// export const miniWebsiteGet = createAsyncThunk(
//   "miniWebsiteGet/get",
//   async (id, { rejectWithValue, dispatch }) => {
//     dispatch(loadingStart());
//     try {
//       const backend = new API();
//       const response = await backend.fetch(API.miniWebsite(id), {
//         method: "GET",
//       });
//       dispatch(loadingEnd());
//       // You may want to return some data here if needed
//       return response;
//     } catch (error) {
//       dispatch(loadingEnd());
//       console.error("Error Adding Product:", error);
//       // You can handle the error here or throw it again if needed
//       return rejectWithValue(error);
//     }
//   }
// );

export const updateCustomerProfile = createAsyncThunk(
  "updateCustomerProfile/post",
  async (data, { rejectWithValue, dispatch }) => {
    dispatch(loadingStart());
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      const backend = new API();
      const response = await backend.fetch(API.updateClientDetails(), {
        method: "POST",
        body: formData,
      });
      dispatch(loadingEnd());

      ToastMessage(response);
      // You may want to return some data here if needed
      return response;
    } catch (error) {
      dispatch(loadingEnd());
      console.error("Error Updating Profile:", error);
      // You can handle the error here or throw it again if needed
      return rejectWithValue(error);
    }
  }
);
