import { createSlice } from "@reduxjs/toolkit";
import {
  inventoryProductListSlice,
  inventoryProductAddSlice,
  inventoryEditeProductSlice,
  inventoryStockAdd,
  inventoryAllProductListSlice,
} from "./index";

const inventoryProductSlice = createSlice({
  name: "userSession",
  initialState: {
    productList: {},
    productListAll: {},
    productListAllIsLoading: false,
    productAdd: {},
    productAddIsLoading: false,
    editeProduct: {},
    editeProductIsLoading: false,
    stockAdd: {},
    stockAddIsLoading: false,
    error: null,
  },
  reducers: {
    clearProductAdd: (state) => {
      state.productAdd = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(inventoryProductListSlice.pending, (state) => {})
      .addCase(inventoryProductListSlice.fulfilled, (state, action) => {
        state.productList = action.payload;
      })
      .addCase(inventoryProductListSlice.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
      })
      .addCase(inventoryAllProductListSlice.pending, (state) => {
        state.productListAllIsLoading = true;
      })
      .addCase(inventoryAllProductListSlice.fulfilled, (state, action) => {
        state.productListAll = action.payload;
        state.productListAllIsLoading = false;
      })
      .addCase(inventoryAllProductListSlice.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.productListAllIsLoading = false;
      })
      .addCase(inventoryProductAddSlice.pending, (state) => {
        state.productAddIsLoading = true;
      })
      .addCase(inventoryProductAddSlice.fulfilled, (state, action) => {
        state.productAdd = action.payload;
        state.productAddIsLoading = false;
      })
      .addCase(inventoryProductAddSlice.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.productAddIsLoading = false;
      })
      .addCase(inventoryEditeProductSlice.pending, (state) => {
        state.editeProductIsLoading = true;
      })
      .addCase(inventoryEditeProductSlice.fulfilled, (state, action) => {
        state.editeProduct = action.payload;
        state.editeProductIsLoading = false;
      })
      .addCase(inventoryEditeProductSlice.rejected, (state, action) => {
        state.editeProductIsLoading = false;
        state.error = action.payload; // Make sure to handle the rejection correctly
      })
      .addCase(inventoryStockAdd.pending, (state) => {
        state.stockAddIsLoading = true;
      })
      .addCase(inventoryStockAdd.fulfilled, (state, action) => {
        state.stockAdd = action.payload;
        state.stockAddIsLoading = false;
      })
      .addCase(inventoryStockAdd.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.stockAddIsLoading = false;
      });
  },
});
export const { clearProductAdd } = inventoryProductSlice.actions;
export default inventoryProductSlice.reducer;
