import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import { Suspense, lazy } from "react";
import RecordExpense from "./pages/expense/RecordExpense";
import ExpensesList from "./pages/expense/ExpensesList";
import CustomerLogin from "./pages/miniWebsite/CustomerLogin";
const Login = lazy(() => import("./pages/login"));
const ProductList = lazy(() => import("./pages/Inventory/productList"));
const AddProduct = lazy(() => import("./pages/Inventory/addProduct"));
const Insights = lazy(() => import("./pages/Inventory/insights"));
const CreateCampaign = lazy(() => import("./pages/marketing/createCampaign"));
const CreateOfferCampaign = lazy(() =>
  import("./pages/marketing/createOfferCampaign")
);
const PlaceholderPage = lazy(() => import("./pages/PlaceHolderPage"));
const RecordSale = lazy(() => import("./pages/sales/RecordSale"));
const SalesEntries = lazy(() => import("./pages/sales/SalesEntries"));
const Calendar = lazy(() => import("./pages/appointment/calendar"));
const Memberships = lazy(() => import("./pages/membership/memberships"));
const Packages = lazy(() => import("./pages/membership/packages"));
const BookAppointmnet = lazy(() =>
  import("./pages/appointment/BookAppointmnet")
);
const AllAppointmnets = lazy(() =>
  import("./pages/appointment/AllAppointmnets")
);
const AddClient = lazy(() => import("./pages/client/AddClient"));
const Clientlist = lazy(() => import("./pages/client/ClientList"));
const AddEnquiry = lazy(() => import("./pages/enquiry/AddEnquiry"));
const EnquiryList = lazy(() => import("./pages/enquiry/EnquiryList"));
const AddStaff = lazy(() => import("./pages/staff/AddStaff"));
const StaffList = lazy(() => import("./pages/staff/StaffList"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const Reports = lazy(() => import("./pages/dashboard/Reports"));
const Insight = lazy(() => import("./pages/dashboard/Insight"));
const Profile = lazy(() => import("./pages/miniWebsite/Profile"));
const BookAppointment = lazy(() =>
  import("./pages/miniWebsite/BookAppointment")
);
const PageLoader = lazy(() => import("./component/commonLayout/PageLoader"));

function PrivateRoute({ component: Component, ...rest }) {
  const isAuthenticated = localStorage.getItem("Customertoken");
  // const isAuthenticated = true;
  return isAuthenticated ? <Outlet /> : <Navigate to="/profile/:id" />;
}
const App = () => {
  return (
    // <MyComponent />
    <Router>
      <Suspense fallback={<PageLoader />}>
        <Routes>
          {/* <Route exact path="/login" element={<Login />} />
          <Route exact path="/profile/:id" element={<Profile />} />
          <Route exact path="/" element={<PrivateRoute />}>
            <Route exact path="/" element={<CreateCampaign />} />
            <Route exact path="/dashboard" element={<PrivateRoute />}>
              <Route exact path="" element={<Dashboard />} />
              <Route exact path="reports" element={<PlaceholderPage />} />
              <Route exact path="insights" element={<Insight />} />
            </Route>
          </Route> */}

          
          {/* <Route path="/profile/:id/*" element={<PrivateRoute />}>
            <Route path="customer" element={<CustomerProfile />} />
          </Route> */}
          <Route path="/profile/:id" element={<Profile />} />
          {/* <Route path="/profile/:id/login" element={<CustomerLogin />} /> */}
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
