import {
  Dashboard,
  Appointment,
  BillFill,
  Inventory,
  Marketing,
  UserAdd,
  UserGroup,
  Enquiry,
  Catalogue,
  CalcButton,
} from "../../assets/icons";
import {
  AtHomeServiceOffering,
  BringBackYourCustomers,
  CelebrateCustomersAnniversary,
  CelebrateCustomersBirthday,
  DiscountOnSpecialOccasion,
  DiscountOnSpecificService,
  FestiveOffers,
  FreeItem,
  LaunchOfSomethingNew,
  OnePlusOneOffer,
  Packages,
  SendBookingConfirmation,
  SendBookingReminders,
  SendingInvoice,
  WelcomeFirstTimeCustomers,
  WinBackCustomers,
} from "../../assets/images/templateTypes";
export const sidebarNavList = [
  {
    id: 1,
    icon: Dashboard,
    tooltip: "Dashboard",
    heading: "Analytics",
    path: "/dashboard",
    showAtBottom: false,
    subMenus: [
      { id: 1, label: "Dashboard", subPath: "#" },
      { id: 2, label: "Reports", subPath: "reports" },
      { id: 3, label: "insights", subPath: "insights" },
    ],
  },
  {
    id: 2,
    icon: BillFill,
    tooltip: "Sales",
    heading: "Sales",
    path: "/sales",
    showAtBottom: false,
    subMenus: [
      { id: 1, label: "Record sale", subPath: "record" },
      { id: 2, label: "Sales entries", subPath: "entries" },
      // { id: 3, label: "Sale insights", subPath: "insight" },
    ],
  },
  {
    id: 3,
    icon: Appointment,
    tooltip: "Appointments",
    heading: "Appointments",
    path: "/appointments",
    showAtBottom: false,
    subMenus: [
      { id: 1, label: "Book appointment", subPath: "book" },
      { id: 2, label: "All appointments", subPath: "allappointments" },
      { id: 2, label: "Calendar", subPath: "calendar" },
    ],
  },
  {
    id: 4,
    icon: Marketing,
    tooltip: "Marketing",
    heading: "Marketing",
    path: "/marketing",
    showAtBottom: false,
    subMenus: [
      { id: 1, label: "Campaigns", subPath: "campaigns" },
      { id: 2, label: "Social media", subPath: "socialmedia" },
      // { id: 2, label: "Marketing insights", subPath: "insight" },
    ],
  },
  {
    id: 5,
    icon: UserGroup,
    tooltip: "Client",
    heading: "Client",
    path: "/client",
    showAtBottom: false,
    subMenus: [
      { id: 1, label: "Add new client", subPath: "addclient" },
      { id: 2, label: "client list", subPath: "clientlist" },
      // { id: 2, label: "Customer insights", subPath: "#" },
    ],
  },
  {
    id: 6,
    icon: Enquiry,
    tooltip: "Enquiry",
    heading: "Enquiry",
    path: "/enquiry",
    showAtBottom: false,
    subMenus: [
      { id: 1, label: "Add enquiry", subPath: "addenquiry" },
      { id: 2, label: "Enquiry entries", subPath: "enquirylist" },
    ],
  },
  {
    id: 7,
    icon: Inventory,
    tooltip: "Inventory",
    heading: "Inventory",
    path: "/inventory",
    showAtBottom: false,
    subMenus: [
      { id: 1, label: "Add new product", subPath: "addproduct" },
      { id: 2, label: "Product list", subPath: "productlist" },
      // { id: 3, label: "Inventory insights", subPath: "insight" },
    ],
  },
  {
    id: 8,
    icon: UserAdd,
    tooltip: "Staff",
    heading: "Staff ",
    path: "/staff",
    showAtBottom: false,
    subMenus: [
      { id: 1, label: "Add staff", subPath: "addstaff" },
      { id: 2, label: "Staff list", subPath: "stafflist" },
    ],
  },
  {
    id: 9,
    icon: CalcButton,
    tooltip: "Expense",
    heading: "Expense ",
    path: "/expense",
    showAtBottom: false,
    subMenus: [
      { id: 1, label: "Record Expense", subPath: "recordExpense" },
      { id: 2, label: "Expenses", subPath: "expenses" },
      // { id: 3, label: "P&L Report", subPath: "expensesReport" },
    ],
  },
  {
    id: 10,
    icon: Catalogue,
    tooltip: "Catalogue",
    heading: "Catalogue",
    path: "/catalogue",
    showAtBottom: true,
    subMenus: [
      { id: 2, label: "Packages", subPath: "packages" },
      { id: 3, label: "Memberships", subPath: "memberships" },
      { id: 1, label: "Services", subPath: "services" },
      { id: 4, label: "Loyalty points", subPath: "loyaltypoints" },
      { id: 5, label: "Gallery", subPath: "gallery" },
    ],
  },
  // {
  //   id: 10,
  //   icon: Settings,
  //   tooltip: "Settings",
  //   heading: "Settings heading",
  //   path: "/settings",
  //   showAtBottom: false,
  //   subMenus: [
  //     { id: 1, label: "Settings sub label 1", subPath: "#" },
  //     { id: 2, label: "Settings sub label 2", subPath: "#" },
  //   ],
  // },
  // {
  //   id: 11,
  //   icon: Logout,
  //   tooltip: "Logout",
  //   heading: "Logout heading",
  //   path: "/login",
  //   showAtBottom: false,
  //   subMenus: [
  //     { id: 1, label: "Logout sub label 1", subPath: "#" },
  //     { id: 2, label: "Logout sub label 2", subPath: "#" },
  //   ],
  // },
];

export const offerCampaignList = [
  {
    id: 1,
    icon: Packages,
    backGroundColor: "#E6F4FE",
    heading: "Packages you are offering",
    subHeading: "",
  },
  {
    id: 2,
    icon: FreeItem,
    backGroundColor: "#E4F3E5",
    heading: "Free item with  specific service",
    subHeading: "",
  },
  {
    id: 3,
    icon: FestiveOffers,
    backGroundColor: "#FFE9F5",
    heading: "Launching festive offers",
    subHeading: "",
  },
  {
    id: 4,
    icon: DiscountOnSpecificService,
    backGroundColor: "#FEEFEC",
    heading: "Discount on specific service",
    subHeading: "",
  },
  {
    id: 5,
    icon: LaunchOfSomethingNew,
    backGroundColor: "#FFFCF1",
    heading: "Launch of something new",
    subHeading: "",
  },
  {
    id: 6,
    icon: DiscountOnSpecialOccasion,
    backGroundColor: "#FFCA9B",
    heading: "Discount on special occasion",
    subHeading: "",
  },
  {
    id: 7,
    icon: OnePlusOneOffer,
    backGroundColor: "#C9E2CB",
    heading: "1+1 services you are offering",
    subHeading: "",
  },
  {
    id: 8,
    icon: AtHomeServiceOffering,
    backGroundColor: "#FAF5FF",
    heading: "At home service you are offering",
    subHeading: "",
  },
];
export const autoCampaignList = [
  {
    id: 1,
    icon: WelcomeFirstTimeCustomers,
    backGroundColor: "#FFFCF1",
    heading: "Welcome first time customers",
    subHeading:
      "Introduce your brand to first time customers and encourage them to become loyal returning customers with post-sale promotions.",
  },
  {
    id: 2,
    icon: CelebrateCustomersAnniversary,
    backGroundColor: "#FEF1E5",
    heading: "Celebrate customer’s anniversary",
    subHeading:
      "Strengthen customer relationships and make them feel special. This campaign wishes your customers “Happy Anniversary”.",
  },
  {
    id: 3,
    icon: WinBackCustomers,
    backGroundColor: "#F5F5F5",
    heading: "Win Back Customers",
    subHeading:
      "Get customers to visit again! This campaign sends an incentive to your old customers who haven’t purchased in a long time.",
  },
  {
    id: 4,
    icon: BringBackYourCustomers,
    backGroundColor: "#FAF5FF",
    heading: "Bring back your customers",
    subHeading:
      "When customers haven’t visited in a while, this campaign sends them an incentive to return again much sooner than they would otherwise.",
  },
  {
    id: 5,
    icon: CelebrateCustomersBirthday,
    backGroundColor: "#E4F3E5",
    heading: "Celebrate customer’s birthday",
    subHeading:
      "Birthdays are the perfect opportunity to build customer relationships. Invite them with an offer to increase your sales.",
  },
];
export const automationCampaignList = [
  {
    id: 1,
    icon: SendBookingReminders,
    backGroundColor: "#FEF1E5",
    heading: "Send booking reminders",
    subHeading:
      "Send automatic booking  reminders to your customers 1 hour prior to their booking ",
    sendBookingConfirmation: false,
    tag: "Inactive",
  },

  {
    id: 2,
    icon: SendBookingConfirmation,
    backGroundColor: "#FEF1E5",
    heading: "Send booking confirmation",
    subHeading:
      "Send automatic booking confirmation to your customers after booking  their appointment",
    sendBookingConfirmation: false,
    tag: "Inactive",
  },
  {
    id: 3,
    icon: SendingInvoice,
    backGroundColor: "#E4F3E5",
    heading: "Send invoice",
    subHeading:
      "Send automatic invoices to your customers as soon as their invoice is generated. No need to waste paper and share invoices manually",
    sendBookingConfirmation: false,
    tag: "Inactive",
  },
];

export const appointmentStatusOptionsArray = [
  { label: "Booked", value: "booked" },
  { label: "Cancel", value: "cancel" },
  { label: "No show", value: "no-show" },
  { label: "Complete", value: "completed" },
];

export const clientTagListData = [
  { value: "VIP", label: "Vip" },
  { value: "Lost", label: "Lost" },
  { value: "Need-Attention", label: "Need attention" },
  { value: "At-Risk", label: "At risk" },
  { value: "Loyal", label: "Loyal" },
  { value: "New", label: "New" },
  { value: "Manual", label: "Manual" },
  { value: "Import", label: "Import" },
];
