import { createSlice } from "@reduxjs/toolkit";
import { miniWebsiteGet, updateCustomerProfile } from "./index";

const updateCustomerProfileslice = createSlice({
  name: "updateCustomerProfileslice",
  initialState: {
    // listMiniWebsite: {},
    updateMyDetailes: {},
    error: null,
  },
  reducers: {
    clearUpdateMyDetailes: (state) => {
      state.updateMyDetailes = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(miniWebsiteGet.pending, (state) => {})
      // .addCase(miniWebsiteGet.fulfilled, (state, action) => {
      //   state.listMiniWebsite = action.payload;
      // })
      // .addCase(miniWebsiteGet.rejected, (state, action) => {
      //   state.error = action.payload; // Make sure to handle the rejection correctly
      // })
      .addCase(updateCustomerProfile.pending, (state) => {})
      .addCase(updateCustomerProfile.fulfilled, (state, action) => {
        state.updateMyDetailes = action.payload;
      })
      .addCase(updateCustomerProfile.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
      });
  },
});

export const { clearUpdateMyDetailes } = updateCustomerProfileslice.actions;
export default updateCustomerProfileslice.reducer;
