import React from "react";
import { Image, Stack } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { Logo, Profile } from "../../../assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };
  const userData = {
    business_logo: localStorage.getItem('business_logo') || Profile,
    name: localStorage.getItem('seller_name') || "user",
    business_name: localStorage.getItem('business_name') || "business name",
  }
  return (
    <header className="top-0 position-sticky overlap-1045">
      <Navbar className="hadder-bg p-4">
        <Navbar.Brand href="#home" className="py-0">
          <Image src={Logo} alt="tapgro" className="h-10" />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Stack gap={2} direction="horizontal">
            <Image
              src={userData?.business_logo}
              alt="image not found"
              roundedCircle
              className="h-10 w-10"
            />
            <div>
              <p className="text-white mb-2 lh-none">{userData?.name}</p>
              <p className="text-white m-0 text-xs lh-none">
                {userData?.business_name}
              </p>
            </div>
            {/* <FontAwesomeIcon
              icon={faChevronDown}
              className="text-gray"
              onClick={logout}
            /> */}
            {/* Hind this icon for now */}
          </Stack>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Header;
