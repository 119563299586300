import { createSlice } from "@reduxjs/toolkit";
import {
  authSendOtp,
  authVerifyOtp,
  authLoginUser,
  contactUsApi,
  getClientDetails,
  addReferralSignup,
} from "./index";

const authSessionSlice = createSlice({
  name: "authSessionSlice",
  initialState: {
    sendOtp: {},
    verifyOtp: {},
    loginUser: {},
    contactUs: {},
    accountDetails: {},
    addReferral: {},
    error: null,
  },
  reducers: {
    clearContactUs: (state) => {
      state.contactUs = {};
    },
    clearVerifyOtp: (state) => {
      state.verifyOtp = {};
    },
    clearAccountDetailes: (state) => {
      state.accountDetails = {};
    },
    clearAddReferral: (state) => {
      state.addReferral = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authSendOtp.pending, (state) => {})
      .addCase(authSendOtp.fulfilled, (state, action) => {
        state.sendOtp = action.payload;
      })
      .addCase(authSendOtp.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
      })
      .addCase(authVerifyOtp.pending, (state) => {})
      .addCase(authVerifyOtp.fulfilled, (state, action) => {
        state.verifyOtp = action.payload;
      })
      .addCase(authVerifyOtp.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
      })
      .addCase(authLoginUser.pending, (state) => {})
      .addCase(authLoginUser.fulfilled, (state, action) => {
        state.loginUser = action.payload;
      })
      .addCase(authLoginUser.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
      })
      .addCase(getClientDetails.pending, (state) => {})
      .addCase(getClientDetails.fulfilled, (state, action) => {
        state.accountDetails = action.payload;
      })
      .addCase(getClientDetails.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
      })
      .addCase(addReferralSignup.pending, (state) => {})
      .addCase(addReferralSignup.fulfilled, (state, action) => {
        state.addReferral = action.payload;
      })
      .addCase(addReferralSignup.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
      })
      .addCase(contactUsApi.pending, (state) => {})
      .addCase(contactUsApi.fulfilled, (state, action) => {
        state.contactUs = action.payload;
      })
      .addCase(contactUsApi.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
      });
  },
});

export const {
  clearContactUs,
  clearAccountDetailes,
  clearAddReferral,
  clearVerifyOtp,
} = authSessionSlice.actions;
export default authSessionSlice.reducer;
